import Promise from 'bluebird';

export default function cancellable(promise) {
  let _isCancelled = false;

  const p = new Promise((resolve, reject) => {
    promise.then(
      (val) => {
        if (_isCancelled) {
          return;
        }

        resolve(val);
      },
      (err) => {
        if (_isCancelled) {
          return;
        }

        reject(err);
      }
    );
  });

  /**
   * Allow someone to cancel the promise
   * @returns {boolean}
   */
  p.cancel = () => (_isCancelled = true);

  return p;
};
