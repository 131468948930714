import pathOr from 'ramda/src/pathOr';
import lensPath from 'ramda/src/lensPath';
import set from 'ramda/src/set';
import complement from 'ramda/src/complement';
import both from 'ramda/src/both';

import OpenAPI from './OpenAPI';

const definitionParametersPath = ['paths', '/chart', 'get', 'parameters'];

const definitionParametersPathOr = pathOr([], definitionParametersPath);

const getImplementationStatus = pathOr('', ['x-meta', 'implementation', 'status']);
const keepImplementation = (validValues) => (parameter) =>
  validValues.includes(getImplementationStatus(parameter));
const keepParameters = (parameterNames) => (parameter) => parameterNames.includes(parameter.name);
const excludeParameters = (parameterNames) => complement(keepParameters(parameterNames));

function get(swagger_definition_url) {
  return OpenAPI.get(swagger_definition_url).then((definition) => {
    const filteredParameters = definitionParametersPathOr(definition).filter(
      both(keepImplementation(['COMPLETE', 'PARTIAL']), excludeParameters(['icac', 'ichm']))
    );

    return set(lensPath(definitionParametersPath), filteredParameters, definition);
  });
}


export default {
  get,
};
