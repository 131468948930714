<template>
  <div>
    <template v-for='(environment, index) in environments'>
      <div v-bind:key='index'>
        <el-card class='mb4'>
          <div slot='header' class='clearfix'>
            <span>HTML (image url) <span v-if='environments.length > 1'>for {{ environment.key }}</span></span>
          </div>
          <div class='text item'>
            <code class='language-html hljs' v-html='environment.html_highlighted'></code>
          </div>
        </el-card>

        <el-card class='mb4'>
          <div slot='header' class='clearfix'>
            <span>HTML (image and editor url) <span v-if='environments.length > 1'>{{ environment.key }}</span></span>
          </div>
          <div class='text item'>
            <code class='language-html hljs' v-html='environment.html_simple_highlighed'></code>
          </div>
        </el-card>

        <el-card class='mb4'>
          <div slot='header' class='clearfix'>
          <span>HTML (image and editor link) (rendered) <span v-if='environments.length > 1'>{{
              environment.key
            }}</span></span>
          </div>
          <div class='text item' v-html='environment.html_simple'>
          </div>
        </el-card>

        <el-card class='mb4'>
          <div slot='header' class='clearfix'>
          <span>HTML (url, image and editor url) (rendered) <span v-if='environments.length > 1'>{{
              environment.key
            }}</span></span>
          </div>
          <div class='text item' v-html='environment.html_extended'>
          </div>
        </el-card>
      </div>
    </template>
  </div>
</template>

<script>
import hljs from 'highlight.js/lib/highlight';
import xml from 'highlight.js/lib/languages/xml';
import 'highlight.js/styles/github.css';

hljs.registerLanguage('xml', xml);

// each change triggers a new router state
export default {
  name: 'HTMLOutput',
  components: {},
  props: {},
  data() {
    return {
      // lambda user
      currentTab: null,
    };
  },

  computed: {
    /*...mapState({
      environments: state => state.environments,
    }),*/

    environments() {

      return this.$store.state.environments.map(environment => {
        const raw_editor_url = (environment.chart.request.url || '').replace('://', '://editor.');
        const html_simple = `<a href="${environment.chart.request.url}"><img src="${environment.chart.request.url}"/></a>\n<a href="${raw_editor_url}">Edit this chart</a>`;

        const html_extended = `<code class="language-http hljs">${environment.chart.request.url}</code>\n<a href="${environment.chart.request.url}"><img src="${environment.chart.request.url}"/></a>\n<a href="${raw_editor_url}">Edit this chart</a></pre>`;

        return {
          key: environment.key,
          html_highlighted: hljs.highlight('xml', `<img src="${environment.chart.request.url}" alt="Static Chart"/>`).value,
          raw_chart_url: environment.chart.request.url || '',
          raw_editor_url: raw_editor_url,
          html_simple: html_simple,
          html_simple_highlighed: hljs.highlight('xml', html_simple).value,
          html_extended: html_extended
        };
      });
    }
  },

  watch: {},

  methods: {},

  mounted() {
  },

  destroyed() {
  }
};

</script>
