/* istanbul ignore file */
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import pack from '../package.json';
import pathOr from 'ramda/src/pathOr';

export default function SentryInit(Vue) {
  if (process.env.NODE_ENV !== 'production') {
    // do not activate sentry
    return;
  }

  Sentry.init({
    dsn: 'https://f999710ef9474197948283e100d30d1e@sentry.io/1863930',
    debug: process.env.NODE_ENV !== 'production',
    environment: process.env.NODE_ENV !== 'production' ? 'development' : process.env.NODE_ENV,
    attachStacktrace: true,
    release: pack.version,

    beforeSend: function (event) {
      // Modify the event here
      if (event.user) {
        // Don't send user's email address
        delete event.user.email;
      }

      const error_value = pathOr('', 'exception.values.0.value'.split('.'), event);

      if (
        event.level === 'error' &&
        (error_value.includes('400') ||
          error_value.includes('Request aborted') ||
          error_value.includes('Network Error'))
      ) {
        // do not send to sentry 400 errors
        // they are valid from our point of view
        return null;
      }

      return event;
    },

    // eslint-disable-next-line no-undef
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
        logErrors: true,
      }),
    ],
  });
  return Sentry;
}
