<template>
  <div class="environment-selector" v-if="environments.length > 1">
    <slot name="before"></slot>
    <el-checkbox :key="index"
                 :label="item.key"
                 :value="item.isActive"
                 @change="toggleEnvironment(item)"
                 border
                 class="env-checkbox"
                 v-for="(item, index) in environments">
    </el-checkbox>
    <slot name="after"></slot>
  </div>
</template>

<style>
  .environment-selector {
    padding-bottom: 1em;
  }
</style>

<script>
import {mapState} from 'vuex';

export default {
  name: 'Viewer',
  props: {},
  computed: {
    ...mapState({
      environments: state => state.environments,
    }),
  },
  data() {
    return {};
  },
  methods: {
    toggleEnvironment({key, isActive}) {
      this.$store.commit('setEnvironmentActive', {key, enableIt: !isActive});
    },
  }
};
</script>
