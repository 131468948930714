import Router from 'vue-router';
import EditorPane from './editor/EditorPane.vue';
import ViewerPane from './viewer/ViewerPane.vue';
import _debounce from 'lodash.debounce';
import * as qs from 'query-string';

const router = new Router({
  mode: 'history',
  routes: [
    {
      // Handle the use-case of the user starts with:
      // https://image-charts.com/chart?chbh=30,20,20&chco=015CAE,015CAE...
      // preprend the domain with "editor." and press enter, thus open:
      // https://editor.image-charts.com/chart?chbh=30,20,20&chco=015CAE,015CAE...
      // then we should redirect to:
      // https://editor.image-charts.com/#https://image-charts.com/chart?chbh=30,20,20&chco=015CAE,015CAE...
      path: '/chart.js/2.8.0',
      redirect: () => {
        return {
          name: 'home',
          query: {}, // no querystring, everything must be moved to hash part
          params: {},
          path: '/chart.js/2.8.0',
          // change protocol and host to current protocol and host
          hash:
            `#${location.protocol}//${location.host}/chart.js/2.8.0?` +
            qs.stringify(qs.parse(location.search.slice(1), { decode: true })),
        };
      },
    },
    {
      // Handle the use-case of the user starts with:
      // https://image-charts.com/chart?chbh=30,20,20&chco=015CAE,015CAE...
      // preprend the domain with "editor." and press enter, thus open:
      // https://editor.image-charts.com/chart?chbh=30,20,20&chco=015CAE,015CAE...
      // then we should redirect to:
      // https://editor.image-charts.com/#https://image-charts.com/chart?chbh=30,20,20&chco=015CAE,015CAE...
      path: '/chart',
      redirect: () => {
        return {
          name: 'home',
          query: {}, // no querystring, everything must be moved to hash part
          params: {},
          path: '/chart',
          // change protocol and host to current protocol and host
          hash:
            `#${location.protocol}//${location.host}/chart?` +
            qs.stringify(qs.parse(location.search.slice(1), { decode: true })),
        };
      },
    },
    {
      // catch all route
      path: '*',
      name: 'home',
      components: {
        left: EditorPane,
        right: ViewerPane,
      },
      props: { left: {}, right: {} },
      beforeEnter: (to, from, next) => {
        if (to.hash !== '') {
          return next();
        }

        // someone is trying to load the `home` route without `chart` query param
        // we define one here
        const route = _toHash(
          to,
          'https://image-charts.com/chart.js/2.8.0?bkg=white&c=%7B%0A%20%20%22type%22%3A%20%22pie%22%2C%0A%20%20%22data%22%3A%20%7B%0A%20%20%20%20%22datasets%22%3A%20%5B%0A%20%20%20%20%20%20%7B%0A%20%20%20%20%20%20%20%20%22data%22%3A%20%5B84%2C%2028%2C%2057%2C%2019%2C%2097%5D%2C%0A%20%20%20%20%20%20%20%20%22backgroundColor%22%3A%20%5B%0A%20%20%20%20%20%20%20%20%20%20%22rgba%28255%2C99%2C132%2C0.5%29%22%2C%0A%20%20%20%20%20%20%20%20%20%20%22rgba%28255%2C159%2C64%2C0.5%29%22%2C%0A%20%20%20%20%20%20%20%20%20%20%22rgba%28255%2C205%2C86%2C0.5%29%22%2C%0A%20%20%20%20%20%20%20%20%20%20%22rgba%2875%2C192%2C192%2C0.5%29%22%2C%0A%20%20%20%20%20%20%20%20%20%20%22rgba%2854%2C162%2C235%2C0.5%29%22%0A%20%20%20%20%20%20%20%20%5D%2C%0A%20%20%20%20%20%20%20%20%22label%22%3A%20%22Dataset%201%22%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%5D%2C%0A%20%20%20%20%22labels%22%3A%20%5B%22Red%22%2C%20%22Orange%22%2C%20%22Yellow%22%2C%20%22Green%22%2C%20%22Blue%22%5D%0A%20%20%7D%0A%7D'
        );

        // set default query state
        next(route);
      },
    },
  ],
});

function _toHash(to, url) {
  return {
    ...to,
    hash: '#' + url,
  };
}

// expose helper
// since pushing into the router is a disk sync operation don't do it often
router.toHash = _debounce(
  function (url) {
    if (router.app.$route.hash.slice(1) === url) {
      return;
    }

    router.push(_toHash(router.app.$route, url));
  },
  500,
  { leading: false, trailing: true }
);

export default router;
