<template>
  <splitpanes class="default-theme" vertical>
    <pane>
      <router-view name="left"></router-view>
    </pane>
    <pane>
      <router-view name="right"></router-view>
    </pane>
  </splitpanes>
</template>

<style lang="scss">
  .app * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .default-theme {
    height: 100vh;
  }
</style>

<script>
export default {
  name: 'App',
  data() {
    return {};
  },

  mounted() {
  },
};
</script>
