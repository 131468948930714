<template>
  <el-tabs class="ic-tabs" type="border-card" v-model="activeTab">
    <slot></slot>
  </el-tabs>
</template>

<style lang="scss">
  .ic-tabs {
    height: 100%;
  }

  .ic-tabs .el-tabs__nav-scroll {
    height: 40px;
  }

  .ic-tabs .el-tabs__content {
    overflow: auto;
    height: calc(100% - 40px);
  }
</style>

<script>
import pathEq from 'ramda/src/pathEq';

export default {
  name: 'tabs',
  props: {
    name: {
      type: String,
      required: true
    },
    defaultTabName: {
      type: String,
      required: true
    }
  },

  data() {
    const queryparam = 'tab_' + this.name;
    const userDefinedActiveTab = this.$route.query[queryparam] || null;


    this.$nextTick(() => {
      // select default tab

      let tabNameToSelect = userDefinedActiveTab;

      const $slots = this.$slots['default'] || [];

      // we would like to select the tab but first we need to make sure that the userDefinedActiveTab still exists
      if (!($slots.some(pathEq('componentOptions.propsData.name'.split('.'), userDefinedActiveTab)))) {
        // fallback, do nothing
        return;
      }

      this.activeTab = tabNameToSelect;
    });

    return {
      // name of the query param to read/write
      queryparam,

      // set the default tab as null
      // set the value later
      activeTab: this.defaultTabName
    };
  },
  watch: {
    // When tab active tab changes, update the route
    activeTab: function (newVal, oldVal) {
      this.$emit('input', newVal);

      if (newVal === oldVal) {
        return;
      }

      if (!isNaN(newVal)) {
        // don't expose index publicly, each tab MUST HAVE a name!
        return;
      }

      if (this.$route.query[this.queryparam] === this.activeTab) {
        // do nothing if we do not change the query param for the current tab
        return;
      }


      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          [this.queryparam]: this.activeTab
        }
      });

    }
  },

  mounted() {
  },
};
</script>
