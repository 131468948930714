import { Promise } from 'bluebird';
import ImageChartsAPI from './ImageChartsAPI';
import { sync } from 'vuex-router-sync';
import omit from 'ramda/src/omit';
import keys from 'ramda/src/keys';
import { signUrl } from './hmac';
import mergeDeepRight from 'ramda/src/mergeDeepRight';
import axios from 'axios';
import cancellable from './cancellable';
import { always, difference, groupBy, pathOr, pipe, prop, tryCatch } from 'ramda';
import Either from 'data.either';
import qs from 'query-string';
import StorePersist from './storePersist';

function _wrap(f) {
  return function(...args) {
    try {
      return Either.Right(new f(...args));
    } catch (err) {
      return Either.Left(err);
    }
  };
}

const URLEither = _wrap(URL);

function getDefaultChartState() {
  return {
    request: {
      url: null,
      isLoading: false,
      promise: null,
      startAt: 0,
    },
    response: {
      endAt: 0,
      errors: [],
      /**
       * @type {Null, String}
       */
      objectURL: null,

      /**
       * @type {Null, Object}
       */
      json: null,
    },
  };
}

const storePersist = StorePersist({ getDefaultChartState });

export default function Store(Vuex, router, { createObjectURL }) {
  const store = new Vuex.Store({
    // @todo let the user activate/deactivate localStorage if needed

    debug: true,
    plugins: [storePersist.plugin],
    state: {
      // vuex-router-sync will override this as read-only:
      route: {
        hash: '#',
      },
      // \vuex-router-sync

      url: {
        full: '',
        enterprise: {
          isEnabled: false,
          account_id: '',
          secret_key: '',
        },

        // current URL path
        pathname: '',

        parameters: {}, // {[name]: 'value', ...}
        parametersErrors: {}, // {[name]: 'errorMessage', ...}
      },

      /**
       * gallery:
       *  'chart': []
       *  'chart.js': []
       */
      gallery: new Promise(() => {}),

      OpenAPI: {
        URL: 'https://image-charts.com/swagger.json',
        specification: new Promise(() => {}),
      },

      documentation: {
        home: 'https://documentation.image-charts.com',
        howToUse: 'https://demo.arcade.software/gNdxOMV9Nkd1YUTP76un?embed'
      },

      environments: [
        {
          key: 'Production',
          value: 'https://image-charts.com',
          isActive: true,
          chart: getDefaultChartState(),
        },
      ],

      developer: {
        // environments edition is only available in debug mode
        isActive: false,

        // if JSON mode is active (image mode is the default)
        isJSONMode: false,

        // if JSON diff mode
        isJSONModeShowChanged: true,
      },

      parameter: {
        active: null,
      },
    },
    getters: {
      chartURL: (state) => state.url.full,
    },

    mutations: {
      /**
       *
       * @param state
       * @param {Promise} specification
       */
      updateOpenAPISpecification(state, specification) {
        state.OpenAPI.specification = specification;
      },

      /**
       *
       * @param state
       * @param {Promise} gallery
       */
      updateGallery(state, gallery) {
        state.gallery = gallery;
      },

      /**
       *
       * @param state
       * @param {OpenAPIParameter} parameter
       */
      updateActiveParameter(state, parameter) {
        state.parameter.active = parameter;
      },

      /**
       *
       * @param state
       * @param {Array<ValidationError>} errors
       */
      setParameterValidationErrors(state, errors) {
        // first remove everything
        state.url.parametersErrors = omit(
          Object.keys(state.url.parametersErrors),
          state.url.parametersErrors
        );

        // add errors
        errors.forEach((error) => {
          const parameterName = error.context.key;
          if (!Object.prototype.hasOwnProperty.call(state.url.parameters, parameterName)) {
            return;
          }

          state.url.parametersErrors = {
            ...state.url.parametersErrors,
            [parameterName]: error,
          };
        });
      },

      /**
       * Set enterprise credential
       * @param state
       * @param {String} account_id
       * @param {String} secret_key
       */
      setEnterpriseCredential(state, { account_id, secret_key }) {
        state.url.enterprise.account_id = account_id;
        state.url.enterprise.secret_key = secret_key;
      },

      upsertURLParameter(state, { name, value }) {
        state.url.parameters = {
          ...state.url.parameters,
          [name.toLowerCase()]: value,
        };
      },

      deleteURLParameter(state, { name }) {
        state.url.parameters = omit([name.toLowerCase()], state.url.parameters);
      },

      setEnvironments(state, environments) {
        let newEnvironments = environments.map((env) => {
          // be sure we have always the same environment properties
          // override these properties value with the defined ones
          return mergeDeepRight(state.environments[0], env);
        });

        // invariant:
        // - the first env is always called 'Production' environment
        // - there must be always AT LEAST one active environment
        if (
          newEnvironments.length === 0 ||
          newEnvironments[0].key !== 'Production' ||
          !newEnvironments.some((env) => env.isActive)
        ) {
          newEnvironments = [state.environments[0]].concat(
            newEnvironments.filter((env) => env.key !== 'Production')
          );
        }

        // set the new environments
        state.environments = newEnvironments.map((env) => {
          env.chart = {
            ...env.chart,
            ...getDefaultChartState(),
          };

          return env;
        });
      },

      /**
       *
       * @param state {Object}
       * @param key {String}
       * @param enableIt {Boolean}
       */
      setEnvironmentActive(state, { key, enableIt }) {
        if (!enableIt) {
          // if this a call to deactivate specified environment

          if (state.environments.filter((env) => env.isActive).length === 1) {
            // forbidden, at least one environment must be selected
            return;
          }
        }

        state.environments.find((env) => env.key === key).isActive = enableIt;
      },

      /**
       * @param state
       * @param isEnabled
       */
      setDeveloperMode(state, { isEnabled }) {
        state.developer.isActive = isEnabled;
        state.developer.isJSONMode = isEnabled;

        if (!isEnabled) {
          //deactivate every other environment
          state.environments.forEach((env, i) => {
            // only keep the first environment active
            env.isActive = i === 0;
          });
        }
      },

      _setDeveloperJSONMode(state, { isEnabled }) {
        state.developer.isJSONMode = isEnabled;
      },
    },

    actions: {
      loadOpenAPI({ commit, state }) {
        const promise = ImageChartsAPI.get(state.OpenAPI.URL);
        commit('updateOpenAPISpecification', promise);
        return promise;
      },

      loadGallery({ commit }) {
        const promise = axios
          .get('https://image-charts.com/gallery.json')
          .then((response) => groupBy(prop('api'), response.data));
        commit(
          'updateGallery',
          promise,
        );
        return promise;
      },

      setEnterpriseMode({ commit, state }, { isEnabled }) {
        state.url.enterprise.isEnabled = isEnabled;

        if (!isEnabled) {
          // if we are deactivating enterprise mode then remove ic* parameters
          // Object {k: v, ...}
          keys(state.url.parameters)
            .filter((name) => name.startsWith('ic'))
            .forEach((name) => commit('deleteURLParameter', { name }));
        }
      },

      setDeveloperJSONMode({ commit }, { isEnabled }) {
        commit('_setDeveloperJSONMode', { isEnabled });

        if (isEnabled) {
          // activate json format
          commit('upsertURLParameter', {
            name: 'jsonic',
            value: 1,
          });
          return;
        }

        // deactivate json format
        commit('deleteURLParameter', { name: 'jsonic' });
      },

      /**
       *
       * @param commit
       * @param state
       * @param url
       * @returns {Promise<Environment[]>} this promise is resolved when all requests are done, it yield the environments
       */
      requestChart({ commit, state }, { url }) {
        const responseToObjectURL = (response) =>
          createObjectURL(
            new Blob([response.data], {
              type: response.headers['content-type'],
            })
          );

        const extractValidationErrorsFromResponseHeaders = pipe(
          pathOr('[]', ['response', 'headers', 'x-ic-error-validation']),
          tryCatch(JSON.parse, always([]))
        );

        const currentRequests = state.environments.reduce((currentRequests, env) => {
          // reset response object
          env.chart.response = getDefaultChartState().response;

          if (env.chart.request.promise) {
            env.chart.request.promise.cancel('concurrent call');
          }

          if (!env.isActive) {
            // environment is inactive, stop there
            return currentRequests;
          }

          env.chart.request.isLoading = true;
          env.chart.request.startAt = new Date();

          // eslint-disable-next-line no-unused-vars
          const [__, ...queryparams] = url.split('?');
          env.chart.request.url = `${env.value}${new URLEither(url)
            .map((r) => r.pathname)
            .getOrElse('/')}?${queryparams.join('?')}`;

          env.chart.request.promise = cancellable(
            Promise.resolve(axios({
              method: 'GET',
              url: env.chart.request.url,
              responseType: 'arraybuffer',
              crossDomain: false,
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'image/png;image/gif;application/json',
              },
            })),
          );

          // add this request to the promise array
          currentRequests.push(env.chart.request.promise.then(() => env));

          function isJSONResponse(response) {
            return response.headers['content-type'].startsWith('application/json');
          }

          function handleResponse(env, response) {
            function readJSONFromResponse(response) {
              const json = String.fromCharCode.apply(null, new Uint8Array(response.data));
              return JSON.parse(json);
            }

            if (isJSONResponse(response)) {
              env.chart.response.json = readJSONFromResponse(response);

              // since it was JSON we won't have an objectURL to display
              return;
            }

            env.chart.response.objectURL = responseToObjectURL(response);
          }

          const s = env.chart.request.promise
            .then(
              (response) => {
                // everything went right, don't forget to reset errors
                env.chart.response.errors = [];
                commit('setParameterValidationErrors', []);

                // if we are not in developer mode and it's the first time we got a JSON
                // then switch to developer mode
                if (!state.developer.isActive && isJSONResponse(response)) {
                  commit('setDeveloperMode', {
                    isEnabled: true,
                  });
                }

                handleResponse(env, response);
              },
              (error) => {
                const response = error.response;
                if (!response) {
                  /*eslint no-debugger:0*/

                  // this might be a network error
                  env.chart.response.errors = [
                    {
                      context: {},
                      message: error.message,
                      path: '',
                      type: 'NetworkError',
                    },
                  ];
                  return;
                }

                // errors can be empty (because header "x-ic-error-validation" was there but had empty values, e.g. :
                // x-ic-error-code: "IC_UNAUTHORIZED_FONT_FAMILY_ERROR"
                // x-ic-error-validation: ""
                const validationErrors = extractValidationErrorsFromResponseHeaders(error);
                commit('setParameterValidationErrors', validationErrors);
                env.chart.response.errors = validationErrors;

                handleResponse(env, response);
              }
            ).finally(() => {
              env.chart.request.isLoading = false;
              env.chart.response.endAt = new Date();
            });

          return currentRequests;
        }, []);

        return Promise.all(currentRequests);
      },
    },
  });

  // whenever enterprise mode is activated OR (account_id OR secret_key) OR chartUrl is changed then recompute the hash
  store.watch(
    (state, getters) =>
      [
        state.url.enterprise.isEnabled,
        state.url.enterprise.account_id,
        state.url.enterprise.secret_key,
        getters.chartURL,
      ].join(','),
    () => {
      const { isEnabled, account_id, secret_key } = store.state.url.enterprise;

      if (!isEnabled || !account_id || !secret_key) {
        // don't (re)compute the hash and sign the URL if enterprise mode is disabled
        return;
      }

      store.state.url.full = signUrl(
        {
          account_id: account_id,
          secret_key: secret_key,
        },
        store.getters.chartURL
      );
    }
  );

  // when internal url changes update store as well as publicly visible (browser) hash
  store.watch(
    (state, getters) => getters.chartURL,
    (newHash) => {
      const newURL = (newHash || '').replace(/^#/, '');

      if (!newHash || !newURL) {
        // url might be undefined
        return;
      }

      // parse new URL
      const urlQuerystring = qs.extract(newURL);

      /**
       * @type {Object}
       */
      const parameters = qs.parse(urlQuerystring);

      /**
       * @type {Object}
       */
      const prevParameters = store.state.url.parameters;

      // remove extraneous parameters (Finds the set (i.e. no duplicates) of all elements in the first list not contained in the second list)
      let extraneousParameters = difference(Object.keys(prevParameters), Object.keys(parameters));
      extraneousParameters.forEach((name) => store.commit('deleteURLParameter', { name }));

      // set only changed parameters
      Object.keys(parameters).forEach((name) => {
        const value = parameters[name];
        if (prevParameters[name] === value) {
          // do nothing, this parameter did not change
          return;
        }

        if (!store.state.url.enterprise.isEnabled && name.startsWith('ic')) {
          // enterprise mode is disabled
          // don't add ic* parameters
          return;
        }

        store.commit('upsertURLParameter', { name, value });
      });

      router.toHash(newURL);
    }
  );

  // when browser url changes (most of the time made by user) update store internal url
  store.watch(
    (state) => state.route.hash,
    (newBrowserHash) => {
      const newURL = newBrowserHash.slice(1);

      if (newURL !== store.state.url.full) {
        store.state.url.full = newURL;
      }
    }
  );

  // when any user-defined parameter changes we need to recompute the whole URL
  store.watch(
    (state) => state.url.parameters,
    () => {
      recomputeURL();
    }
  );

  // when environments changes we need to recompute the whole URL
  store.watch(
    (state) => state.environments.map((env) => [env.isActive, env.value]),
    () => {
      recomputeURL();
    }
  );

  function recomputeURL() {
    const querystring = qs.stringify(store.state.url.parameters, {
      encode: true, // we need to encore the parameter values otherwise character like "%" or " " won't be encoded and "% " won't be displayed like it should
    });

    // we need to be sure we computed a different chartURL that the one currently displayed
    // otherwise we will get an infinite redirection loop
    const { url, query } = qs.parseUrl(store.getters.chartURL);

    store.state.url.pathname = new URLEither(url).map((r) => r.pathname).getOrElse('/');

    const chartURL = `${store.state.environments.find((env) => env.isActive).value}${
      store.state.url.pathname
    }?${querystring}`;

    // We need to encode the URL otherwise chart with values like "% Low" won't be correctly displayed:
    // https://image-charts.com/chart?cht=bhs&chs=250x70&chd=t:19&chco=39e600&chf=bg,s,f3f1e9&chxt=x,x&chm=N**%%20Low,000000,0,-1,18&chxl=1:|Low|Medium|High&chxp=0,0,25,42,100|1,13,34,71&chxs=0N**%,000000

    const prevChartURL = `${url}?${qs.stringify(query, { encode: true })}`;

    if (prevChartURL === chartURL) {
      // do nothing
      return;
    }

    store.state.url.full = chartURL;
  }

  // keep store and router in sync
  sync(store, router);

  return store;
}
