<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <div>
        <slot name="header-before"></slot>
        <span>Environments</span>
        <slot name="header-after"></slot>
      </div>
    </div>
    <el-row justify="space-around" type="flex">
      <ICEditableKVList
          :data="environments"
          :deletable="isDeletable"
          :editable="isEditable"
          :i18n="editableI18n"
          :insertable="true"
          :moveableDown="isMoveableDown"
          :moveableUp="isMoveableUp"
      >
        <el-button v-if="environments.length > 0"
                   :disabled="isSameEnvironments(environments)"
                   type="primary"
                   @click="save"
        >Save
        </el-button>
      </ICEditableKVList>
    </el-row>
  </el-card>
</template>

<style lang="scss">

</style>

<script>
import clone from 'ramda/src/clone';
import {always, tryCatch} from 'ramda';

export default {
  name: 'Environment',
  components: {},
  props: {},

  data() {
    return {
      environments: clone(this.$store.state.environments),

      editableI18n: {
        empty: {
          title: 'No defined environment',
          description: 'Afin de pouvoir utiliser le mode multi-environnement de cet éditeur, merci d\'ajouter l\'environnement de production, développement et même pourquoi pas celui de Google Image-Charts',
          button: 'Create first environment'
        },
        new: {
          button: {
            type: 'default',
            text: 'Add new environment'
          },
          default: {
            key: 'My environment',
            value: 'http://domain.tld',
          }
        }
      }
    };
  },
  watch: {},

  methods: {
    save() {
      // we need to clone the array otherwise it will be passed as reference instead of value
      this.$store.commit('setEnvironments', clone(this.environments));

      // reload the app
      window.location.reload();
    },

    isSameEnvironments(environments) {
      return tryCatch(() => JSON.stringify(environments), always('')) === tryCatch(() => JSON.stringify(this.$store.state.environments), always(''));
    },

    isEditable: (element, i) => {
      return i > 0;
    },
    isDeletable: (element, i) => {
      return i > 0;
    },

    isMoveableUp: (element, i, data) => {
      return i > 1;
    },

    isMoveableDown: (element, i, data) => {
      return i > 0 && i < data.length - 1;
    },
  },

  mounted() {

  },
};
</script>
