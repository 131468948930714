<template>
  <el-form-item :for='name' :label='name'>
    <div :class='{"integer-parameter-group": !isEmpty}'>
      <el-input-number
        :id='name'
        :disabled='disabled'
        :max='max'
        :min='min'
        :placeholder='description'
        :required='required'
        :value='value'
        style='width:100%'
        @focus="$emit('active')"
        @input='inputChanged'
      >
      </el-input-number>
      <el-button v-if='!isEmpty' @click='reset()'>
        <i class='el-icon-delete'></i>
      </el-button>
    </div>
    <slot></slot>
  </el-form-item>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'IntegerParameter',
  props: {
    type: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    default: {
      required: false,
    },
    pattern: {
      type: String,
      required: false,
    },
    min: {
      type: Number,
      required: false,
      default: -Infinity,
    },
    max: {
      type: Number,
      required: false,
      default: +Infinity,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState({
      // eslint-disable-next-line no-unused-vars
      userDefinedParameters: state => state.url.parameters,
    }),

    value: function() {
      return this.userDefinedParameters[this.name];
    },

    isEmpty: function() {
      return String(this.value || '').trim() === '';
    },
  },

  data() {
    return {};
  },
  methods: {
    inputChanged(value) {
      // do not change `this.value`, it will be automatically updated from store
      this.$emit('change', value);
    },
    reset() {
      this.$emit('change', null);
    },
  },
  mounted() {
  },
};
</script>

<style>
.integer-parameter-group {
  display: inline-table;
}

.integer-parameter-group > div {
  display: table-cell;
}

.integer-parameter-group input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0px solid #000;
}

.integer-parameter-group button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
