<template>
  <div>
    <el-card class="box-card">
      <div class="clearfix" slot="header">
        <div>
          <slot name="header-before"></slot>
          <span>Debug mode</span>
          <slot name="header-after"></slot>
        </div>
      </div>
      <el-row justify="space-around" type="flex">
        <el-button @click="exitDebugMode()" type="danger">Exit debug mode</el-button>
      </el-row>
    </el-card>
  </div>
</template>

<style lang="scss">

</style>

<script>
export default {
  name: 'DebugPane',
  components: {
  },
  props: {},

  data() {
    return {};
  },
  watch: {},

  methods: {
    exitDebugMode() {
      this.$store.commit('setDeveloperMode', {isEnabled: false});
    }
  },

  mounted() {

  },
};
</script>
