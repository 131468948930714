<template>
  <iframe ref="iframe"
          v-loading="isLoading"
          :src="url"
          class="documentation-frame"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          element-loading-spinner="el-icon-loading" element-loading-text="Loading..."></iframe>
</template>

<style lang="scss">
  .documentation-frame {
    width: 100%;
    overflow: auto;
    height: 80vh;
    border: 0px;
    background: #fff;
  }
</style>

<script>
import {mapState} from 'vuex';
import pathOr from 'ramda/src/pathOr';

export default {
  name: 'DocumentationPane',
  data() {
    return {
      isLoading: true,

      loadingInstance: null,

      /**
         * Load nothing by default
         * @property {string} URL to
         */
      url: 'about:blank'
    };
  },

  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },

  computed: mapState({
    activeParameter: state => state.parameter.active,
    documentationHome: state => state.documentation.home,
  }),

  watch: {
    isActive: function (newVal) {
      // when documentation tab is not active
      // if a user click on a parameter
      // and THEN click on the documentation tab
      // this listener will be called and we will have to display the current documentation
      if (newVal === true) {
        this.displayDocumentation(this.activeParameter);
      }
    }
  },

  created() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'updateActiveParameter' && this.isActive) {
        // only update the documentation iframe if the documentation tab is currently displayed
        this.displayDocumentation(this.activeParameter);
      }
    });
  },

  methods: {
    displayDocumentation(parameter) {
      const getDocumentationLink = pathOr(null, ['x-meta', 'link']);
      const url = getDocumentationLink(parameter);

      // some parameter do not have documentation links
      const isValidDocumentationUrl = !!url;
      // and we need to be sure this is not the currently displayed url
      const isCurrentlyDisplayed = url === this.url;

      if (isValidDocumentationUrl && !isCurrentlyDisplayed) {
        this.url = url;
      }

      if(this.url === 'about:blank'){
        this.url = this.documentationHome;
      }

      this.resizeIframe();
    },
    resizeIframe() {
      try {
        if (!this.$refs.iframe) {
          debugger;
        }

        // find a better way (errr magic number!)
        this.$refs.iframe.style.height = this.$parent.$el.parentElement.offsetHeight - 40 + 'px';
      } catch (err) {
        console.error(err);
      }
    }
  },

  mounted() {
  }
};
</script>
