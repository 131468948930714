<template>
  <div>
    <p>Parameter {{name}} of type {{type}} not implemented</p>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'NotImplemented',
  props: {
    type: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    default: {
      required: false,
    },
    pattern: {
      type: String,
      required: false,
    },
    minLength: {
      type: Number,
      required: false,
    },
    maxLength: {
      type: Number,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
  },
};
</script>
