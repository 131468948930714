<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div>
          <slot name="header-before"></slot>
          <span v-if="title" v-text="title"></span>
          <slot name="header-after"></slot>
        </div>
      </div>
      <el-form label-width='120px'>
        <slot name='form-before'></slot>
        <template v-for='(parameter, index) in parameters'>
          <div v-bind:key='index'>
            <component
              :is='_getFormComponent(parameter)'
              v-bind:key='index'
              v-bind='parameter'
              v-bind:disabled='disabled'
              v-bind:value='{obj:userDefinedParameters, key:parameter.name}'
              @active='updateActiveParameter(parameter)'
              @change='setParameterValue(parameter, $event)'
            >
              <el-alert
                v-if='validationErrors[parameter.name]' :key="index+'_error'"
                :closable='false'
                :title='validationErrors[parameter.name]'
                effect='dark'
                style='margin-top:1em'
                type='error'>
              </el-alert>
            </component>
          </div>
        </template>
        <slot name="form-after"></slot>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import NotImplemented from './parameters/NotImplemented.vue';
import StringParameter from './parameters/StringParameter.vue';
import EnumParameter from './parameters/EnumParameter.vue';
import IntegerParameter from './parameters/IntegerParameter';

import { mapMutations, mapState } from 'vuex';
import map from 'ramda/src/map';
import pathOr from 'ramda/src/pathOr';

export default {
  name: 'Editor',
  props: {
    parameters: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: mapState({
    // eslint-disable-next-line no-unused-vars
    userDefinedParameters: state => state.url.parameters,
    parametersErrors: state => state.url.parametersErrors
  }),

  data() {
    return {
      validationErrors:{}
    };
  },

  watch: {
    /**
       * @param {Object<String, ValidationError>} parametersErrors
       */
    parametersErrors: function (parametersErrors) {
      const extractMessage = pathOr('Invalid value', ['context', 'name']);
      this.validationErrors = map(extractMessage, parametersErrors);
    }
  },

  methods: {
    ...mapMutations(['updateActiveParameter', 'upsertURLParameter', 'deleteURLParameter']),


    _getFormComponent(parameter) {
      if (['string', 'boolean'].includes(parameter.type) && Array.isArray(parameter.enum)) {
        return EnumParameter;
      }

      if (parameter.type === 'string') {
        return StringParameter;
      }

      if (parameter.type === 'integer') {
        return IntegerParameter;
      }

      return NotImplemented;
    },

    setParameterValue(parameter, value) {
      if (value === '' || value === null || value === undefined) {
        this.deleteURLParameter({name: parameter.name});
        return;
      }

      this.upsertURLParameter({name: parameter.name, value: value});
    }
  },

  mounted() {
  },
};
</script>
