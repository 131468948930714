<template>
  <div>
    <template v-for='(environment, index) in environments'>
      <div v-bind:key='index'>
        <el-card class='mb4'>
          <div slot='header' class='clearfix'>
            <span>URL <span v-if='environments.length > 1'>for {{ environment.key }}</span></span>
          </div>
          <div class='text item'>
            <code class='language-http hljs' v-html='environment.code' />
          </div>
        </el-card>

        <el-card class='mb4'>
          <div slot='header' class='clearfix'>
          <span>Markdown (image and editor url) <span v-if='environments.length > 1'>for {{
              environment.key
            }}</span></span>
          </div>
          <div class='text item'>
            <code class='language-http hljs'>[![chart]({{ environment.raw_chart_url }})]({{
                environment.raw_editor_url
              }})</code>
          </div>
        </el-card>
      </div>
    </template>
  </div>
</template>

<script>
import hljs from 'highlight.js/lib/highlight';
import http from 'highlight.js/lib/languages/http';
import 'highlight.js/styles/github.css';

hljs.registerLanguage('http', http);

// each change triggers a new router state
export default {
  name: 'URLOutput',
  components: {},
  props: {},
  data() {
    return {
      // lambda user
      currentTab: null,
    };
  },

  computed: {
    /*...mapState({
      environments: state => state.environments,
    }),*/

    environments() {
      return this.$store.state.environments.map(environment => {
        const raw_editor_url = (environment.chart.request.url || '').replace('://', '://editor.');
        const html_simple = `<a href="${environment.chart.request.url}"><img src="${environment.chart.request.url}"/></a>\n<a href="${raw_editor_url}">Edit this chart</a></pre>`;

        const html_extended = `<code class="language-http hljs">${environment.chart.request.url}</code>\n<a href="${environment.chart.request.url}"><img src="${environment.chart.request.url}"/></a>\n<a href="${raw_editor_url}">Edit this chart</a></pre>`;

        return {
          key: environment.key,
          code: hljs.highlight('http', environment.chart.request.url || '').value,
          raw_chart_url: environment.chart.request.url || '',
          raw_editor_url: raw_editor_url,
          html_simple: html_simple,
          html_extended: html_extended
        };
      });
    }
  },

  watch: {},

  methods: {},

  mounted() {
  },

  destroyed() {
  }
};

</script>
