import VuexPersistence from 'vuex-persist';
import { name } from '../package.json';
import pipe from 'ramda/src/pipe';
import toPairs from 'ramda/src/toPairs';
import filter from 'ramda/src/filter';
import fromPairs from 'ramda/src/fromPairs';

const filterObjByKey = (f) =>
  pipe(
    toPairs,
    filter(([key], i) => f(key, i)),
    fromPairs
  );

const onlyKeepEnterpriseParameters = filterObjByKey((paramName) => paramName.startsWith('ic'));

export default function factory({ getDefaultChartState }) {
  return new VuexPersistence({
    key: name,
    storage: window.localStorage,

    // only persist when the route changed (because it's after a debounce :))
    // eslint-disable-next-line no-unused-vars
    filter: ({ type, payload }) => {
      return [
        'setEnvironments',
        'setDeveloperMode',
        'setDeveloperJSONMode',
        'route/ROUTE_CHANGED',
      ].includes(type);
    },

    restoreState: (key, storage) => {
      try {
        const raw = storage.getItem(key);
        if (!raw || typeof raw !== 'string') {
          return null;
        }

        const json = JSON.parse(raw);

        // 2021-02-19 - in order to be backward compatible we need to remove "/chart" for the endpoints
        if (key === 'editor' && typeof json === 'object' && Array.isArray(json.environments)) {
          //
          json.environments = json.environments.map(env => {
            if (env.value.includes('/chart')) {
              env.value = env.value.replace('/chart', '');
            }

            return env;
          });

        }
        // \ 2021-02-19

        return json;
      } catch (err) {
        return null;
      }
    },

    // only persist enterprise and environment configuration as well as ic* parameters in local-storage
    reducer: (state) => {
      return {
        url: {
          enterprise: state.url.enterprise,
          parameters: onlyKeepEnterpriseParameters(state.url.parameters),
        },
        environments: state.environments.map(({ key, value, isActive }) => {
          return {
            // only keep the base informations
            key,
            value,
            isActive,
            // reset runtime information
            chart: getDefaultChartState(),
          };
        }),
        developer: state.developer,
      };
    },
  });
}
