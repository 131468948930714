<template>
  <el-form-item :for='name' :label='name'>
    <div :class='{"enum-parameter-group": !isEmpty}'>
      <el-select
        :id='name'
        :disabled='disabled'
        :value='value'
        style='width:100%'
        @change="$emit('change', $event)"
        @focus="$emit('active')"
      >
        <el-option
          v-for='val in $props.enum'
          :key='val'
          :label='val'
          :value='val'>
        </el-option>
      </el-select>
      <el-button v-if='!isEmpty' @click='reset()'>
        <i class='el-icon-delete'></i>
      </el-button>
    </div>
    <slot></slot>
  </el-form-item>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'EnumParameter',
  props: {
    type: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    enum: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapState({
      // eslint-disable-next-line no-unused-vars
      userDefinedParameters: state => state.url.parameters,
    }),

    value: function() {
      return this.userDefinedParameters[this.name];
    },

    isEmpty: function() {
      return String(this.value || '').trim() === '';
    },
  },

  data() {
    return {};
  },
  methods: {
    reset() {
      this.$emit('change', null);
    },
  },

  mounted() {
  },
};
</script>

<style>
.enum-parameter-group {
  display: inline-table;
}

.enum-parameter-group > div {
  display: table-cell;
}

.enum-parameter-group input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0px solid #000;
}

.enum-parameter-group button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
