import crypto from 'crypto';
import qs from 'query-string';

function _sign(secretKey, query) {
  return crypto.createHmac('sha256', secretKey).update(query).digest('hex');
}

export function signUrl({ account_id, secret_key }, rawUrl) {
  // Then generate the watermark-free url
  const { url: endpoint, query } = qs.parseUrl(rawUrl);

  // remove query.ichm if it exists, it's going to be generated below
  delete query.ichm;

  const rawQuerystring = qs.stringify(
    {
      ...query,
      // add or override icac
      icac: account_id,
    },
    // We need to encode the URL otherwise chart with values like "% Low" won't be correctly displayed:
    // https://image-charts.com/chart?cht=bhs&chs=250x70&chd=t:19&chco=39e600&chf=bg,s,f3f1e9&chxt=x,x&chm=N**%%20Low,000000,0,-1,18&chxl=1:|Low|Medium|High&chxp=0,0,25,42,100|1,13,34,71&chxs=0N**%,000000
    { encode: true }
  );

  const signature = _sign(secret_key, rawQuerystring);
  return `${endpoint}?${rawQuerystring}&ichm=${signature}`;
}
