import axios from 'axios';

const ERR_INVALID_API_DEFINITION = new Error('Invalid API definition');

function get(url) {
  return axios({ url }).then((response) => {
    // check that everything's alright with what we got (duck typing)
    try {
      if (response.data.paths['/chart'].get.parameters.length <= 2) {
        throw ERR_INVALID_API_DEFINITION;
      }
    } catch (_err) {
      const err = new Error(ERR_INVALID_API_DEFINITION.message);
      err.cause = _err;
      throw err;
    }

    return response.data;
  });
}

export default { get, ERR_INVALID_API_DEFINITION };
