<template>
  <Promised :promise="gallery">
    <!-- Use the "pending" slot to display a loading message -->
    <template v-slot:pending>
      <p>Loading...</p>
    </template>
    <!-- The default scoped slot will be used as the result -->
    <template v-slot="gallery">
      <el-card
        v-for="(item, index) in gallery[pathname.includes('chart.js') ? 'chart.js' : 'chart']"
        :key="index"
        class="gallery-image"
        justify="space-around"
        type="flex"
        @click="onSelectURL(item.url)"
      >
        <div slot="header" class="clearfix" @click="onSelectURL(item.url)">
          <div>
            <span>{{ item.category }} - {{ item.title }}</span>
          </div>
        </div>
        <el-image
          :src="item.url"
          alt="Static chart exemple"
          fit="scale-down"
          @click="onSelectURL(item.url)"
        ></el-image>
      </el-card>
    </template>
    <!-- The "rejected" scoped slot will be used if there is an error -->
    <template v-slot:rejected="error">
      <p>Ugh. We could not load the gallery, please refresh the app to try again.</p>
    </template>
  </Promised>
</template>

<style lang="scss">
.gallery-image {
  margin-bottom: 20px;
  cursor: pointer;

  &:hover,
  &.selected {
    border-color: #409eff;
  }

  &.selected,
  &.el-card__header {
    color: rgb(64, 158, 255);
    font-weight: 500;
    border-bottom-color: rgb(64, 158, 255);
  }
}

.gallery-image .el-card__body {
  text-align: center;
  display: block;
  position: relative;

  img {
    max-height: 200px;
  }
}

.load-button {
  display: block;
  margin: 0 auto;
}

.mb0 {
  margin-bottom: 0px;
}
</style>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Gallery',
  props: {},

  computed: {
    ...mapGetters(['chartURL']),
    ...mapState({
      // eslint-disable-next-line no-unused-vars
      gallery: (state) => {
        return state.gallery;
      },
      pathname: (state) => state.url.pathname,
    }),
  },

  data() {
    return {};
  },
  watch: {},

  methods: {
    onSelectURL(url) {
      if (url === this.chartURL) {
        return;
      }
      this.$router.toHash(url);
    },
  },

  mounted() {},
};
</script>
