import sortBy from 'ramda/src/sortBy';
import map from 'ramda/src/map';

/**
 *
 * @param {Array<Object>} parameters
 * @param {Object} userDefinedParameters
 */
// function orderParameters(parameters, userDefinedParameters = {}) {
//   return sort((parameterA, parameterB) => {
//     /*
//     Si fonctionComparaison(a, b) est inférieur à 0, on trie a avec un indice inférieur à b (a sera classé avant b)
//     Si fonctionComparaison(a, b) renvoie 0, on laisse a et b inchangés l'un par rapport à l'autre, mais triés par rapport à tous les autres éléments. Note : la norme ECMAScript ne garantit pas ce comportement, par conséquent tous les navigateurs (par exemple les versions de Mozilla antérieures à 2003) ne respectent pas ceci.
//     Si fonctionComparaison(a, b) est supérieur à 0, on trie b avec un indice inférieur à a.
//     fonctionComparaison(a, b) doit toujours renvoyer le même résultat à partir de la même paire d'arguments. Si la fonction renvoie des résultats incohérents, alors l’ordre dans lequel sont triés les éléments n’est pas défini.
//      */
//     const isAUserDefined = userDefinedParameters.hasOwnProperty(parameterA.name);
//     const isBUserDefined = userDefinedParameters.hasOwnProperty(parameterB.name);
//
//     if (isAUserDefined && isBUserDefined) {
//       // both parameters are user defined, we now need to compare on the parameter name itself (ordering lexicalography)
//       return parameterA.name.localeCompare(parameterB.name);
//     }
//
//     if (isAUserDefined) {
//       // A is higher in the last than B
//       return -1;
//     }
//
//     if (isBUserDefined) {
//       // B is higher in the last than A
//       return 1;
//     }
//
//     // if both are not defined, order then lexicalography
//     return parameterA.name.localeCompare(parameterB.name);
//
//   }, parameters);
// }

/**
 * @param {Object} userDefinedParameters
 * @param {Array} parameters
 */
function groupParametersByCategory(userDefinedParameters, parameters) {
  const categories = parameters.reduce((m, parameter) => {
    const categoryName =
      parameter['x-meta'] && parameter['x-meta'].category ? parameter['x-meta'].category : 'COMMON';

    if (!Object.prototype.hasOwnProperty.call(m, categoryName)) {
      m[categoryName] = {
        parameters: [],
        name: categoryName,
      };
    }

    m[categoryName].parameters.push(parameter);
    return m;
  }, {});

  return map((category) => {
    // @todo order parameters based on userDefinedParameters
    //category.parameters = orderParameters(category.parameters, userDefinedParameters);
    // currently we only sort parameters by name
    category.parameters = sortBy((p) => p.name, category.parameters);
    return category;
  }, categories);
}

export { groupParametersByCategory };
