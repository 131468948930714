<template>
  <Promised :promise="categories$">
    <!-- Use the "pending" slot to display a loading message -->
    <template v-slot:pending>
      <p>Loading editor...</p>
    </template>

    <slot></slot>

    <!-- The default scoped slot will be used as the result -->
    <template v-slot="categories">
      <ICTabs default-tab-name="form" name="editor">
        <el-tab-pane name="form">
          <span slot="label" class="nav__brand nav__editor">Form Parameters</span>

          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <div>
                <span>API Endpoint</span>
              </div>
            </div>

            <Promised :promise="paths$">
              <template v-slot:pending>
                <p>Loading API paths...</p>
              </template>
              <template v-slot="paths">
                <el-select
                  id="endpoint"
                  :value="endpoint"
                  style="width: 100%"
                  @change="onEndpointChange($event)"
                >
                  <el-option v-for="val in paths" :key="val" :label="val" :value="val"> </el-option>
                </el-select>
              </template>
            </Promised>
          </el-card>

          <FormEditor
            v-if="
              endpoint.includes('/chart') && categories[endpoint] && categories[endpoint].COMMON
            "
            :parameters="categories[endpoint].COMMON.parameters"
            title="General parameters"
          ></FormEditor>
          <span v-else>Image-Charts does not currently supports this endpoint for editing.</span>
        </el-tab-pane>
        <el-tab-pane name="enterprise">
          <span slot="label">Enterprise Parameters</span>
          <EnterpriseForm
            v-if="categories[endpoint] && categories[endpoint].ENTERPRISE"
            :parameters="categories[endpoint].ENTERPRISE.parameters"
          ></EnterpriseForm>
        </el-tab-pane>
        <el-tab-pane name="gallery">
          <span slot="label">Gallery</span>
          <Gallery></Gallery>
        </el-tab-pane>
        <el-tab-pane name="url">
          <span slot="label">URL Parameters</span>
          <URLEditor></URLEditor>
        </el-tab-pane>
        <el-tab-pane name="environments">
          <span slot="label">Environments</span>
          <Environments></Environments>
        </el-tab-pane>
        <el-tab-pane v-if="developerMode.isActive" name="developer">
          <span slot="label">Developer Mode</span>
          <DeveloperPane></DeveloperPane>
        </el-tab-pane>
      </ICTabs>
    </template>

    <!-- The "rejected" scoped slot will be used if there is an error -->
    <template v-slot:rejected="error">
      <p>Error: {{ error.message }}</p>
    </template>
  </Promised>
</template>

<style lang="scss">
.nav__editor.nav__brand #HW_badge_cont {
  left: auto;
  right: auto;
  top: auto;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
</style>

<script>
import FormEditor from './form/FormEditor';
import EnterpriseForm from './form/EnterpriseForm';
import URLEditor from './url/URLEditor';
import { mapState } from 'vuex';
import { groupParametersByCategory } from './parameters';
import Gallery from './Gallery';
import DeveloperPane from './DeveloperPane';
import Environments from '../environments/Environments';
import { mapObjIndexed, pipe } from 'ramda';

const PANE_NAME = 'EditorPane';
export default {
  name: PANE_NAME,
  props: {},

  components: {
    Gallery,
    FormEditor,
    URLEditor,
    EnterpriseForm,
    DeveloperPane,
    Environments,
  },

  computed: {
    ...mapState({
      /**
       * @returns {Promise<any>}
       */
      specification: (state) => state.OpenAPI.specification,

      gallery: (state) => state.gallery,

      // eslint-disable-next-line no-unused-vars
      userDefinedParameters: (state) => state.url.parameters,

      // eslint-disable-next-line no-unused-vars
      endpoint: (state) => state.url.pathname,

      // eslint-disable-next-line no-unused-vars
      developerMode: (state) => state.developer,
    }),
  },

  data() {
    return {
      /**
       * @type {Promise<Object>}
       */
      categories$: new Promise(() => {}),

      /**
       * @type {Promise<Array>}
       */
      paths$: new Promise(() => {}),

      // set the default tab, read the query
      activeTab: this.$route.query[PANE_NAME] || null,
    };
  },

  methods: {
    onEndpointChange(endpoint) {
      debugger;
      this.gallery.then((gallery) => {
        console.log(gallery);

        // switch endpoints
        if (endpoint === '/chart') {
          this.$router.toHash(gallery['chart'][0].url);
          return;
        }

        if (endpoint.includes('/chart.js')) {
          this.$router.toHash(gallery['chart.js'][0].url);
        }
      });
    },
  },

  watch: {
    specification: function (newSpecificationPromise) {
      if (!(newSpecificationPromise instanceof Promise)) {
        // when using time-travel
        return;
      }

      this.categories$ = this.specification.then((specification) => {
        const paths = pipe(
          mapObjIndexed((path) =>
            groupParametersByCategory(this.userDefinedParameters, path.get.parameters)
          )
        )(specification.paths);
        return paths;
      });

      this.paths$ = this.specification.then((specification) => Object.keys(specification.paths));
    },

    activeTab: function (newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          [PANE_NAME]: this.activeTab,
        },
      });
    },
  },

  mounted() {},
};
</script>

<style scoped>
.box-card {
  margin-bottom: 16px;
}
</style>
