<template>
  <div>
    <template v-if="developer.isJSONMode">
      <EnvironmentSelector class="mt10 mb10">
        <template v-slot:before>
          <el-button @click="refresh">Refresh (ctrl + r)</el-button>
        </template>
      </EnvironmentSelector>
    </template>

    <el-checkbox v-model="developer.isJSONModeShowChanged" class="mt10 mb10"
      >Show unchanged values</el-checkbox
    >

    <el-alert v-if="errorMessage" class="mt10 mb10" type="warning">{{ errorMessage }}</el-alert>
    <div v-if="developer.isJSONMode" class="mt10 mb10" v-html="visual"></div>
  </div>
</template>

<style lang="scss">
@import url('~jsondiffpatch/dist/formatters-styles/html.css');

.json-diff {
  display: block;
  font-family: monospace;
  white-space: pre;
}

.mt10 {
  margin-top: 10px;
}

.mb10 {
  margin-bottom: 10px;
}
</style>
<script>
import { mapGetters, mapState } from 'vuex';
import path from 'ramda/src/path';
import EnvironmentSelector from '../environments/EnvironmentSelector';
import jsondiffpatch from 'jsondiffpatch/dist/jsondiffpatch.umd.slim';

// just for debug
window.jsondiffpatch = jsondiffpatch;
// \

const getJSONFromEnv = path('chart.response.json'.split('.'));

export default {
  name: 'Viewer',
  props: {},
  components: {
    EnvironmentSelector,
  },
  computed: {
    ...mapState({
      environments: (state) => state.environments,
      developer: (state) => state.developer,
    }),
    ...mapGetters(['chartURL']),
  },
  data() {
    return {
      visual: null,
      delta: null,
      left: null,
      right: null,
      errorMessage: null,
    };
  },
  mounted() {
    if (this.developer.isActive) {
      document.addEventListener('keypress', this.onKeyPress, false);
    }
  },
  unmounted() {
    if (this.developer.isActive) {
      document.removeEventListener('keypress', this.onKeyPress);
    }
  },
  created() {
    this.$store.watch(
      (state) =>
        state.environments.map((env) => [
          env.isActive,
          env.chart.response.json,
          state.developer.isJSONModeShowChanged,
        ]),
      () => {
        if (!this.$store.state.developer.isActive) {
          // not in developer mode, do nothing!
          return;
        }

        this.computeDiff();
      }
    );

    this.computeDiff();
  },
  watch: {},
  methods: {
    onKeyPress(event) {
      if (event.ctrlKey && event.key === 'r') {
        this.refresh();
      }
    },
    refresh() {
      this.$store.dispatch('requestChart', { url: this.chartURL });
    },
    computeDiff() {
      this.errorMessage = '';
      this.left = {};
      this.right = {};
      this.delta = {};
      this.visual = null;

      // are we in json mode ?
      if (!this.developer.isJSONMode) {
        this.errorMessage = 'JSON mode must be active to display a diff';
        return;
      }

      const envWithJson = this.environments.filter(getJSONFromEnv);

      if (envWithJson.length < 2) {
        this.errorMessage = `Only TWO environments must be selected AND return a JSON response (currently only ${envWithJson
          .map((env) => env.key)
          .join(', ')} was selected and yield a JSON response)`;
        return;
      }

      this.left = getJSONFromEnv(envWithJson[0]);
      this.right = getJSONFromEnv(envWithJson[1]);
      this.delta = jsondiffpatch.diff(this.left, this.right);

      // beautiful html diff
      this.visual = jsondiffpatch.formatters.html.format(this.delta, this.left);

      if (this.developer.isJSONModeShowChanged) {
        jsondiffpatch.formatters.html.showUnchanged();
      } else {
        jsondiffpatch.formatters.html.hideUnchanged();
      }
    },
  },
};
</script>
