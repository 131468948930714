<template>
  <div>
    <el-input
        type="textarea"
        autosize
        resize="vertical"
        :value="value"
        @input="inputChanged"/>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import difference from 'ramda/src/difference';


function humanEditableURL(userDefinedParameters) {
  return Object.keys(userDefinedParameters).reduce((m, name) => m.concat(name + '=' + userDefinedParameters[name]), []).join('\n');
}

export default {
  name: 'URLEditor',
  props: {},

  computed: {
    ...mapState({
      // eslint-disable-next-line no-unused-vars
      userDefinedParameters: state => state.url.parameters,
    }),

    value:function(){
      return humanEditableURL(this.$store.state.url.parameters);
    }
  },

  data() {
    return {};
  },

  methods: {
    ...mapMutations(['updateActiveParameter', 'upsertURLParameter', 'deleteURLParameter']),

    /**
       * Called each time a parameter is changed
       * @param {String} newParameters
       */
    inputChanged: function (newParameters) {
      const prevParameters = this.$store.state.url.parameters;
      const parameters = newParameters.trim().split('\n').reduce(
        (parameters, line) => {

          const pair = line.split('=');

          if(!pair[0] && !pair[1]){
            return parameters;
          }

          const param = pair[0];
          const value = pair[1] || ''; // in case there was nothing behind "="

          const name = param.toLowerCase();

          parameters[name] = value || '';

          if (prevParameters[name] !== parameters[name]) {
            this.upsertURLParameter({name, value});
          }

          return parameters;
        }
        , {});

      // remove extraneous parameters (Finds the set (i.e. no duplicates) of all elements in the first list not contained in the second list)
      const extraneousParameters = difference(Object.keys(prevParameters), Object.keys(parameters));
      extraneousParameters.forEach(parameterName => this.deleteURLParameter({name: parameterName}));
    },
  },

  mounted() {
  },
};
</script>
