<template>
  <el-form>
    <el-form-item v-for='(item, index) in data' v-bind:key='index'>
      <el-row :gutter='20' justify='space-between' type='flex'>
        <el-col>
          <el-input v-model='item.key' :disabled='!editable(item, index, data)'></el-input>
        </el-col>
        <el-col>
          <el-input v-model='item.value' :disabled='!editable(item, index, data)'></el-input>
        </el-col>
        <el-col>
          <el-button-group>
            <el-button :disabled='!deletable(item, index, data)' @click='remove(index)'>
              <i class='el-icon-delete-solid'></i>
            </el-button>

            <el-button :disabled="!moveableUp(item, index, data)" @click="moveUp(index)">
              <i class="el-icon-arrow-up"></i>
            </el-button>

            <el-button :disabled="!moveableDown(item, index, data)" @click="moveDown(index)">
              <i class="el-icon-arrow-down"></i>
            </el-button>
          </el-button-group>
        </el-col>
      </el-row>
    </el-form-item>

    <el-alert
      v-if='data.length === 0'
      :title='i18n.empty.title'
      type="success"
    >
      <p v-text="i18n.empty.description"></p>
      <el-button @click="addNewData" v-text="i18n.empty.button"></el-button>
    </el-alert>

    <el-form-item class="actions">
      <el-button v-if='data.length > 0' :type='i18n.new.button.type' @click='addNewData'
                 v-text='i18n.new.button.text'></el-button>
      <slot></slot>
    </el-form-item>
  </el-form>
</template>

<style lang="scss">
  .actions {
    text-align: right;
    padding-right: 10px;
  }

  .center {
    text-align: center;
  }

  .right {
    text-align: right;
  }
</style>

<script>
export default {
  name: 'ICEditableKVList',
  props: {
    data: {
      type: Array,
      required: true
    },
    insertable: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Function,
      default: () => true
    },
    deletable: {
      type: Function,
      default: () => true
    },
    moveableUp: {
      type: Function,
      default: (item, i) => i !== 0
    },
    moveableDown: {
      type: Function,
      default: (item, i, data) => i > data.length - 1
    },
    i18n: {
      type: Object,
      default: () => {
        return {
          empty: {
            title: 'No data',
            description: 'Oops there are no data',
            button: 'Create first data'
          },
          new: {
            button: {
              type: 'default',
              text: 'Add new data'
            },
            default: {
              key: 'key-name',
              value: 'value-name',
            }
          }
        };
      }
    }
  },

  data() {
    return {};
  },
  methods: {
    addNewData() {
      this.data.push({key: this.i18n.new.default.key, value: this.i18n.new.default.value});
    },

    remove(index) {
      this.data.splice(index, 1);
    },

    moveUp(index) {
      const item = this.data[index];
      this.remove(index);
      this.data.splice(index - 1, 0, item);
    },

    moveDown(index) {
      const item = this.data[index];
      this.remove(index);
      this.data.splice(index + 1, 0, item);
    }
  },

  mounted() {
  },
};
</script>
