<template>
  <FormEditor :parameters="parameters" :disabled="!isEnabled || !(account_id && secret_key)">
    <template v-slot:header-after>
      <el-switch id="enterprise_enabled"
                 :value="isEnabled"
                 @input="onEnterpriseEnableChange"
                 active-text="Enterprise and Enterprise+ mode"></el-switch>
    </template>
    <template v-slot:form-before>
      <el-form-item label="account_id" for="enterprise_account_id">
        <el-input
            id="enterprise_account_id"
            placeholder="The account_id you receive when subscribing to Image-Charts"
            :value="account_id"
            @input="setAccountId"
            :required="isEnabled"
            :disabled="!isEnabled"
        ></el-input>
      </el-form-item>

      <el-form-item label="secret_key" for="enterprise_secret_key">
        <el-input
            id="enterprise_secret_key"
            placeholder="The secret_key you receive when subscribing to Image-Charts"
            :value="secret_key"
            type="password"
            @input="setSecretKey"
            :required="isEnabled"
            :disabled="!isEnabled"
        ></el-input>
      </el-form-item>
    </template>
  </FormEditor>
</template>

<script>
import FormEditor from './FormEditor';
import {mapMutations, mapState} from 'vuex';

export default {
  name: 'EnterpriseForm',
  props: {
    parameters: {
      type: Array,
      required: true
    }
  },
  components: {
    FormEditor
  },

  computed: {
    ...mapState({
      // eslint-disable-next-line no-unused-vars
      isEnabled: state => state.url.enterprise.isEnabled,
      account_id: state => state.url.enterprise.account_id,
      secret_key: state => state.url.enterprise.secret_key,
    }),
    //
    // value:function(){
    //   return humanEditableURL(this.$store.state.url.parameters)
    // }
  },

  data() {
    return {};
  },

  methods: {
    ...mapMutations(['updateActiveParameter', 'upsertURLParameter', 'deleteURLParameter']),

    onEnterpriseEnableChange: function(isEnabled) {
      this.$store.dispatch('setEnterpriseMode', {isEnabled: isEnabled});
    },

    setAccountId: function(account_id) {
      this.$store.commit('setEnterpriseCredential', {account_id, secret_key: this.secret_key});
    },

    setSecretKey: function(secret_key) {
      this.$store.commit('setEnterpriseCredential', {account_id: this.account_id, secret_key});
    }
  },

  mounted() {
  },
};
</script>
