<template>
  <ICTabs default-tab-name="image" name="viewer" @input="onTabChange">
    <el-tab-pane v-if="developerMode.isActive" name="json-diff">
      <span slot="label">JSON diff</span>
      <JsonDiff></JsonDiff>
    </el-tab-pane>
    <el-tab-pane class="documentation-pane" name="how-to-use">
      <span slot="label">📘 How to use</span>
      <HowToUsePane :isActive="currentTab === 'how-to-use'"></HowToUsePane>
    </el-tab-pane>
    <el-tab-pane name="image">
      <span slot="label">
        Image output
        <span v-if="environments[0].chart.request.isLoading"><i class="el-icon-loading"></i></span>
        <span v-if="environments[0].chart.response.errors.length > 0"> - error <i
          class="el-icon-warning-outline"></i></span>
        <span v-if="environments[0].chart.response.errors.length === 0 && !environments[0].chart.request.isLoading"> - {{
            +environments[0].chart.response.endAt - +environments[0].chart.request.startAt
          }}ms</span>
      </span>
      <div ref="imageContainer">
        <div class="flex">
          <div v-if="developerMode.isActive" class="flex-left pb4">
            <el-switch
              :value="developerMode.isJSONMode"
              active-text="JSON mode"
              inactive-text="Image mode"
              @change="switchJSONMode">
            </el-switch>
          </div>
          <div class="flex-right">
            <EnvironmentSelector>
            </EnvironmentSelector>
          </div>
        </div>


        <template v-for="(environment, index) in environments">
          <el-card v-if="environment.isActive" :key="index" :class="{chart_mode: !developerMode.isJSONMode}"
                   class="box-card">
            <div v-if="developerMode.isActive" slot="header" class="clearfix">
              <span>{{ environment.key }}</span>
            </div>
            <div class="text item">
              <div v-if="environment.chart.request.isLoading" class="center" style="height:100px">
                <i class="el-icon-loading"></i>
              </div>

              <!-- display errors -->
              <template v-for="(error, index) in environment.chart.response.errors">
                <el-alert
                  :key="index"
                  :closable="false"
                  :title="extractMessage(error)"
                  effect="dark"
                  type="error">
                </el-alert>
              </template>

              <!-- display IMAGE output -->
              <div class="chart_container">
                <a v-if="!environment.chart.request.isLoading && environment.chart.response.objectURL"
                   :key="index"
                   :href="environment.chart.request.url" rel="noopener"
                   target="_blank">
                  <el-image :src="environment.chart.response.objectURL" alt="Static Chart" fit="scale-down">

                    <div slot="placeholder" class="image-slot">
                      Loading<span class="dot">...</span>
                    </div>

                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </a>
              </div>

              <!-- display JSON output -->
              <pre v-if="developerMode.isJSONMode && environment.chart.response.json"
                   class="json-output">{{ JSON.stringify(environment.chart.response.json, null, 2) }}</pre>
            </div>
          </el-card>
        </template>
      </div>
    </el-tab-pane>
    <el-tab-pane name="url">
      <span slot="label">URL output</span>
      <URLOutput></URLOutput>

    </el-tab-pane>
    <el-tab-pane name="html">
      <span slot="label">HTML output</span>
      <HTMLOutput></HTMLOutput>
    </el-tab-pane>
    <el-tab-pane class="documentation-pane" name="documentation">
      <span slot="label">Documentation</span>
      <DocumentationPane :isActive="currentTab === 'documentation'"></DocumentationPane>
    </el-tab-pane>
    <el-tab-pane name="feedback">
      <span slot="label">🕷 Found a bug? Send a feedback!</span>
      <div class="feedback">
        <el-button type="primary" @click="reportFeedback()">
          Did you found a bug?<br/><br/>
          Please let us know 😉
        </el-button>
      </div>
    </el-tab-pane>
  </ICTabs>
</template>

<style>
a {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  /*height: 100%;*/
}

.json-output {
  margin: 0;
}

.center {
  text-align: center;
  vertical-align: center;
}

.feedback {
  text-align: center;
  margin-top: 30%;
}

.mb4 {
  margin-bottom: 10px;
}

/** Developer mode */
.pb4 {
  padding-bottom: 10px;
}

.flex {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}

.flex-left, .flex-right {
}

.flex-right {
}

.env-checkbox {
  margin-right: 0;
}

.chart_mode .el-card__body {
  background: radial-gradient(black 15%, transparent 16%) 0 0, radial-gradient(black 15%, transparent 16%) 8px 8px, radial-gradient(rgba(255, 255, 255, .1) 15%, transparent 20%) 0 1px, radial-gradient(rgba(255, 255, 255, .1) 15%, transparent 20%) 8px 9px;
  background-color: #282828;
  background-size: 16px 16px;
}
</style>

<script lang="js">
import { mapGetters, mapState } from "vuex";
import DocumentationPane from "../documentation/DocumentationPane";
import pathOr from "ramda/src/pathOr";
import debounce from "lodash.debounce";
import EnvironmentSelector from "../environments/EnvironmentSelector";
import JsonDiff from "./JsonDiff";
import URLOutput from "./URLOutput";
import HTMLOutput from "./HTMLOutput";
import HowToUsePane from '../documentation/HowToUsePane';

// each change triggers a new router state
export default {
  name: "Viewer",
  components: { HowToUsePane, DocumentationPane, EnvironmentSelector, JsonDiff, URLOutput, HTMLOutput },
  props: {},
  data() {
    return {
      // lambda user
      currentTab: null
    };
  },

  computed: {
    ...mapState({
      parametersErrors: state => state.url.parametersErrors,
      environments: state => state.environments,
      developerMode: state => state.developer
    }),
    ...mapGetters(['chartURL']),
  },

  watch: {
    chartURL(newURL) {
      this.doRequest(newURL);
    },
    // when environments is updated, resize its container
    environments() {
      this.resizeContainer();
    },
  },

  methods: {
    extractMessage(error) {
      return pathOr('Invalid value', ['message'])(error);
    },
    /**
     * Everytime the tab change
     * @param {String} newTab new selected tab
     */
    onTabChange(newTab) {
      this.currentTab = newTab;
    },

    /**
     * This function must be thread safe and also discard previous calls
     * @param url
     */
    doRequest: debounce(function (url) {
      this.$store.dispatch('requestChart', {url});
    }, 500, {leading: false, trailing: true}),

    resizeContainer: debounce(function () {
      try {
        // find a better way (err magic number!)
        this.$refs.imageContainer.style.height = this.$parent.$el.parentElement.offsetHeight - 40 + 'px';
      } catch (err) {
        console.error(err);
      }
    }),


    //
    //
    // Developer Mode
    //
    //
    switchJSONMode(isEnabled) {
      this.$store.dispatch('setDeveloperJSONMode', {isEnabled});
    },

    reportFeedback() {
      setTimeout(function() {
        console.log("called!");
        window.Sentry.showReportDialog({
          title: "Found a bug? Send a feedback!",
          subtitle: "We answer everytime!",
          subtitle2: "",
          labelSubmit: "Send feedback"
        });
      }, 100);
      setTimeout(function() {
        throw new Error("Bug report");
      });

    }
  },

  mounted() {
    this.doRequest(this.chartURL);
    window.addEventListener("resize", this.resizeContainer);
  },

  destroyed() {
    window.removeEventListener("resize", this.resizeContainer);
  }
};
</script>
