<template>
  <div>
    <h1>How to maximize Image Charts in 3 steps</h1>
    <ol>
      <li>Read the <a target='_blank' href='https://www.image-charts.com/blog/how-to-get-started-with-image-charts'>step-by-step
        guide</a> to the Image Charts editor
      </li>
      <li>OR learn <a target='_blank' href='https://demo.arcade.software/kCmVLvQoIsEGc06XksPZ?embed'>doing with this
        interactive tutorial</a> of the editor
      </li>
    </ol>
    <iframe :src='url' class='documentation-frame' frameborder='0' webkitallowfullscreen
            v-loading='isLoading'
            ref='iframe'
            element-loading-background='rgba(0, 0, 0, 0.8)'
            element-loading-spinner='el-icon-loading' element-loading-text='Loading...'
            mozallowfullscreen allowfullscreen
    ></iframe>

    <ol start="3">
      <li>Watch this video to learn How to Auto-Create Charts in an Email (or anywhere) with Zapier</li>
    </ol>

    <iframe style='width:100%;height:50vh;' src='https://www.youtube.com/embed/bZg5saLCJGI' frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen></iframe>
  </div>

</template>

<style lang='scss' scoped>
h1 {
  margin: 0px;
}

ol {
  margin: 10px 0px 0 20px;
  padding: 0;
}

ol li {
  padding: 10px 0px;
}

ol li a {
  display: inline-block;
}
</style>

<script>
import { mapState } from 'vuex';
import pathOr from 'ramda/src/pathOr';

export default {
  name: 'HowToUsePane',
  data() {
    return {
      isLoading: true,

      loadingInstance: null,

      /**
       * Load nothing by default
       * @property {string} URL to
       */
      url: 'about:blank',
    };
  },

  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },

  computed: mapState({
    activeParameter: state => state.parameter.active,
    howToUse: state => state.documentation.howToUse,
  }),

  watch: {
    isActive: function(newVal) {
      // when documentation tab is not active
      // if a user click on a parameter
      // and THEN click on the documentation tab
      // this listener will be called and we will have to display the current documentation
      if (newVal === true) {
        this.displayDocumentation(this.activeParameter);
      }
    },
  },

  methods: {
    displayDocumentation() {
      if (this.url === 'about:blank') {
        this.url = this.howToUse;
      }

      this.resizeIframe();
    },
    resizeIframe() {
      try {
        if (!this.$refs.iframe) {
          //
        }

        // find a better way (errr magic number!)
        this.$refs.iframe.style.height = '595px';
      } catch (err) {
        console.error(err);
      }
    },
  },

  mounted() {
  },

};
</script>
