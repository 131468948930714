// Import service-worker
import register from './registerSW';
// VueJS librairies
import Vue from 'vue';
import Router from 'vue-router';
import Vuex from 'vuex';
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Col,
  Form,
  FormItem,
  Image,
  Input,
  InputNumber,
  Loading,
  Option,
  Row,
  Select,
  Switch,
  TabPane,
  Tabs,
  Tooltip,
} from 'element-ui';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import 'element-ui/lib/theme-chalk/fonts/element-icons.ttf';
import 'element-ui/lib/theme-chalk/fonts/element-icons.woff';
// Import ElementUI css deps
import 'element-ui/lib/theme-chalk/index.css'; // Ensure you are using css-loader
import { Promised } from 'vue-promised';
import { Pane, Splitpanes } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';
// register internal components
import ICTabs from './components/ICTabs';
import ICEditableKVList from './components/ICEditableKVList';
// App layers
import router from './router';
// App main component
import App from './App.vue';
import Sentry from './sentry';
import Store from './store';

register();

Vue.use(Router);

window.Sentry = Sentry(Vue);

Vue.use(Vuex);

Vue.use(Col);
Vue.use(Row);
Vue.use(Alert);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Select);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Option);
Vue.use(Card);
Vue.use(Checkbox);
Vue.use(Image);
Vue.use(Tooltip);
Vue.use(Switch);
Vue.use(Loading);
// configure ElementUI language
locale.use(lang);

Vue.component('Promised', Promised);

Vue.component('splitpanes', Splitpanes);
Vue.component('pane', Pane);

Vue.component('ICTabs', ICTabs);
Vue.component('ICEditableKVList', ICEditableKVList);
export default function app({ el, createObjectURL }) {
  if (!createObjectURL) {
    createObjectURL = window.URL.createObjectURL;
  }

  const store = Store(Vuex, router, {
    createObjectURL,
  });

  const vue = new Vue({
    router,
    store,
    el,
    render: (h) => h(App),
  });

  // Load documentation
  store.dispatch('loadOpenAPI').catch((err) => {
    console.error('Could not load OpenAPI definition', err);
  });

  store.dispatch('loadGallery').catch((err) => {
    console.error('Could not load Gallery definition', err);
  });

  return {
    vue,
    router,
    store,
  };
}
